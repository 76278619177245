import React from 'react';
import { Link } from 'gatsby';

import Byline from '../../authors/byline';

import {
  formattedBookTitle,
} from '../../../helpers/books';

export default (props) => {
  const bookData = props.bookNode.data;

  return (
    <div className="related-books-grid__cell">
      <div className="related-categories">
        Also in{' '}
        {props.relatedCategories.map(category => (
          <Link to={`/category/${category.data.Slug}`}>
            { category.data.Label }
          </Link>
        ))}
      </div>
      {(bookData.Signed_Cover_URL) ? (
        <div className="related-books-grid__cover">
          <Link
            to={`/book/${bookData.Slug}`}
            className="related-books-grid__cover-wrapper"
          >
            <img
              src={ bookData.Signed_Cover_URL }
              alt="Cover"
            />
          </Link>
        </div>
      ) : null}
      <div className="related-books-grid__metadata">
        <p className="title">
          <Link to={`/book/${bookData.Slug}`}>
            { formattedBookTitle(bookData.Title, bookData.Subtitle) }
          </Link>
        </p>
        <p className="byline">
          <Byline
            authorNodes={ bookData.Authors }
            orderedAuthors={ bookData.Ordered_Authors }
          />
        </p>
      </div>
    </div>
  );
}
