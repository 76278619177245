import React from 'react';
import { Link } from 'gatsby';
import marked from 'marked';
import moment from 'moment';

import BuyButtons from './buy-buttons';
import Byline from '../../components/authors/byline';
import CategoryBar from '../../components/categories/bar';
import PriceHistory from './price-history';

import {
  formattedBookTitle,
} from '../../helpers/books';

import '../../styles/books/profile.scss';

export default (props) => {
  const book = props.book;
  const publisher = book.data.Publisher[0];

  return (
    <div className="book-profile">
      <div className="book-profile__sidebar">
        {(book.data.Signed_Cover_URL) ? (
          <img src={ book.data.Signed_Cover_URL } alt="Cover" />
        ) : null}
      </div>
      <div className="book-profile__title">
        <h1>
          {formattedBookTitle(book.data.Title, book.data.Subtitle)}
        </h1>
      </div>
      <div className="book-profile__metadata">
        <p className="book-profile__byline">
          <Byline
            authorNodes={book.data.Authors}
            orderedAuthors={book.data.Ordered_Authors}
          />
        </p>
        <p className="book-profile__publishing">
          <Link to={`/publisher/${publisher.data.Slug}`}>{publisher.data.Name}</Link>, {book.data.Year}
        </p>
        {(book.data.Categories && book.data.Categories.length > 0) ? (
          <CategoryBar categoryNodes={ book.data.Categories } />
        ) : null}
        <BuyButtons
          kindleAsin={ book.data.Kindle_ASIN }
          hardcopyAsin={ book.data.Hardcopy_ASIN }
          isbn={ book.data.ISBN_13 }
        />
        {(book.data.Short_Description || book.data.Long_Description) && (
          <>
            <h3>
              Publisher Description
            </h3>
            {book.data.Short_Description && (
              <p
                className="book-profile__short-description"
                dangerouslySetInnerHTML={{
                  __html: marked(book.data.Short_Description).replace('<p>','').replace('</p>','')
                }}
              />
            )}
            {book.data.Long_Description && (
              <p
                className="book-profile__long-description"
                dangerouslySetInnerHTML={{
                  __html: marked(book.data.Long_Description).replace('<p>','').replace('</p>','')
                }}
              />
            )}
          </>
        )}
      </div>
      <div className="book-profile__kindle-price">
        {(book.data.Kindle_ASIN) ? (
          <PriceHistory
            history={ book.data.Price_History }
            asin={ book.data.Kindle_ASIN }
          />
        ) : null}
      </div>
      {(book.data.Notes && book.data.Notes.length > 0) ? (
        <div className="book-profile__notes">
          {book.data.Notes.map(({ data }, idx) => (
            <div key={`note-${idx}`} className="book-profile__note-block">
              <div
                className="book-profile__note-content"
                dangerouslySetInnerHTML={{ __html: marked(data.Note) }}
              />
              <div className="book-profile__note-metadata">
                Posted by {data.Contributor[0].data.Short_Name} on {moment(data.Created_Date).format('D MMM YYYY')}
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};
