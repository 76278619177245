import React from 'react';

import PageColumn from '../../layout/page-column';
import RainbowBar from '../../common/rainbow-bar';
import RecipesList from './recipes-list';
import Section from '../../layout/section';
import SectionHeader from '../../common/section-header';

export default (props) => {
  return (
    <>
    <RainbowBar width={'thin'} />
    <Section topSpacing="section" bottomSpacing="section" color="light-gray">
      <PageColumn>
        <SectionHeader label="Recipes" />
        <RecipesList
          recipes={ props.recipes }
        />
      </PageColumn>
    </Section>
    </>
  );
};