import React from 'react';
import moment from 'moment';
import yaml from 'js-yaml';

import { amazonUrlFromAsin } from '../../helpers/books'

import '../../styles/books/price-history.scss';

const formattedPrice = (price) => {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formatter.format(parseFloat(price));
};

export default (props) => {
  const history = yaml.load(props.history).filter(item => item.price !== 'None');

  const lowestPriceIdx = history.reduce((acc, val, idx, self) => {
    if (val.price <= self[acc].price) {
      return idx;
    }
    return acc;
  }, 0);

  const highestPriceIdx = history.reduce((acc, val, idx, self) => {
    if (val.price >= self[acc].price) {
      return idx;
    }
    return acc;
  }, 0);

  const formattedDate = (date) => moment(date, "YYYY-MM-DD").fromNow();

  const recordingSince = (
    <div className="price-history__recording-since">
      Started tracking { formattedDate(history[0].timestamp) }
    </div>
  );

  const currentPrice = (
    <a
      href={ amazonUrlFromAsin(props.asin) }
      className={`price-history__grid-cell price-history__current-price ${(history[history.length - 1].price < 5) ? 'price-history__discount' : ''}`}>
      <span className="header">Current Price</span>
      <span className="price">
        <span className="dollars">$</span>
        { formattedPrice(history[history.length - 1].price) }
      </span>
      <span className="time">Since { formattedDate(history[history.length - 1].timestamp) }</span>
    </a>
  );

  const lowestPrice = (
    <div className="price-history__grid-cell price-history__lowest-price">
      <span className="header">Lowest Price</span>
      <span className="price">
        <span className="dollars">$</span>
        { formattedPrice(history[lowestPriceIdx].price) }
      </span>
      <span className="time">{ formattedDate(history[lowestPriceIdx].timestamp) }</span>
    </div>
  );

  const highestPrice = (
    <div className="price-history__grid-cell price-history__highest-price">
      <span className="header">Highest Price</span>
      <span className="price">
        <span className="dollars">$</span>
        { formattedPrice(history[highestPriceIdx].price) }
      </span>
      <span className="time">{ formattedDate(history[highestPriceIdx].timestamp) }</span>
    </div>
  );

  return (
    <div className="price-history">
      <div className="price-history__header">
        Kindle Price Tracking
      </div>
      <div className="price-history__price-grid">
        { currentPrice }
        { lowestPrice }
        { highestPrice }
      </div>
      { recordingSince }
    </div>
  );
};
