import React from 'react';

import {
  amazonUrlFromAsin,
  bookshopUrlFromIsbn,
} from '../../helpers/books';

import '../../styles/books/buy-buttons.scss';

export default (props) => (
  <>
    {(props.kindleAsin || props.hardcopyAsin || props.isbn) ? (
      <div className="book-profile__buy-buttons">
        <ul>
          {(props.kindleAsin) ? (
            <li>
              <a href={amazonUrlFromAsin(props.kindleAsin)}>Amazon Kindle</a>
            </li>
          ) : null}
          {(props.hardcopyAsin) ? (
            <li>
              <a href={amazonUrlFromAsin(props.hardcopyAsin)}>Amazon</a>
            </li>
          ) : null}
          {(props.isbn) ? (
            <li>
              <a href={bookshopUrlFromIsbn(props.isbn)}>Bookshop.org</a>
            </li>
          ) : null}
        </ul>
      </div>
    ) : null}
  </>
);