import React from 'react';
import yaml from 'js-yaml';

import '../../../styles/books/recipes-list.scss';

const recipesJsxFromYaml = (recipes) => {
  const recipeList = yaml.load(recipes);

  if (recipeList.length > 1) {
    return (
      <>
        {recipeList.map(group => (
          <>
            <p className="recipes-section">{ group.label }</p>
            <ul>
              {group.recipes.map(recipe => (
                <li>{ recipe }</li>
              ))}
            </ul>
          </>
        ))}
      </>
    )
  }

  return (
    <ul>
      {recipeList[0].recipes.map(recipe => (
        <li>{ recipe }</li>
      ))}
    </ul>
  )
}

export default (props) => (
  <div className="recipes-list">
    {recipesJsxFromYaml(props.recipes)}
  </div>
);