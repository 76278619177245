import React from 'react';

import RelatedBooksGridCell from './related-grid-cell';

import '../../../styles/books/related-grid.scss';

export default (props) => (
  <div className="related-books-grid">
    {props.relatedBooks.map(({ node, relations }) => (
      <RelatedBooksGridCell
        bookNode={ node }
        relatedCategories={ relations }
      />
    ))}
  </div>
);
