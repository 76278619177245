import React from 'react';

import PageColumn from '../../layout/page-column';
import RainbowBar from '../../common/rainbow-bar';
import RelatedBooksGrid from './related-grid';
import Section from '../../layout/section';
import SectionHeader from '../../common/section-header';

const relatedBooks = (thisBook, allBookNodes) => {
  if (!thisBook.data.Categories) {
    return [];
  }

  const categorySlugs = thisBook.data.Categories.map(({ data }) => data.Slug);
  let filteredBookRelations = [];

  const bookRelations = allBookNodes.map(bookNode => {
    if (bookNode.data.Slug === thisBook.data.Slug) {
      // Don't count relations on a book to itself
      return {
        node: bookNode,
        relations: 0,
      };
    }

    if (!bookNode.data.Categories) {
      // Don't attempt to count relations on books without categories
      return {
        node: bookNode,
        relations: 0,
      };
    }

    const sharedCategories = bookNode.data.Categories.filter(category => {
      if (category.data.Relatable) {
        return categorySlugs.includes(category.data.Slug);
      }

      return false;
    });

    return {
      node: bookNode,
      relations: sharedCategories,
    }
  });

  filteredBookRelations = bookRelations.filter(relation => relation.relations.length > 0);
  filteredBookRelations = filteredBookRelations.sort((a, b) => b.relations.length - a.relations.length);
  filteredBookRelations = filteredBookRelations.slice(0, 6);

  return filteredBookRelations;
};

export default (props) => {
  const relatedBookData = relatedBooks(props.book, props.allBookNodes);

  if (relatedBookData && relatedBookData.length > 0) {
    return (
      <>
      <RainbowBar width={'thin'} />
      <Section topSpacing="section" bottomSpacing="section" color="light-gray">
        <PageColumn>
          <SectionHeader label="Related Books" />
          <RelatedBooksGrid relatedBooks={ relatedBookData } />
        </PageColumn>
      </Section>
      </>
    );
  }

  return null;
};
