import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import BaseLayout from '../components/layout/base-layout';
import PageColumn from '../components/layout/page-column';
import BookProfile from '../components/books/profile';
import RecipesSection from '../components/books/recipes/recipes-section';
import RelatedBooksSection from '../components/books/related-books/related-books-section';
import Section from '../components/layout/section';


const Book = (props) => {
  const book = props.data.book;

  return (
    <BaseLayout>
      <Helmet>
        <title>{ book.data.Title }</title>
        <meta
          property="og:title"
          content={ book.data.Title }
        />
      </Helmet>
      <Section topSpacing="section" bottomSpacing="section">
        <PageColumn>
          <BookProfile book={ book } />
        </PageColumn>
      </Section>
      {(book.data.Recipes) ? (
        <RecipesSection recipes={ book.data.Recipes } />
      ) : null}
      <RelatedBooksSection
        book={ book }
        allBookNodes={ props.data.books.edges.map(({ node }) => node) }
      />
    </BaseLayout>
  );
}

export default Book;

export const query = graphql`
query($slug:String) {
  book:airtable(table: {eq: "Books"}, data: {Slug: {eq: $slug}}) {
    data {
      ...BookMetadata
      Hardcopy_ASIN
      ISBN_13
      Kindle_ASIN
      Kindle_Price
      Recipes
      Year
      Price_History
      Short_Description
      Long_Description
      Signed_Cover_URL
      Publisher {
        data {
          Name
          Slug
        }
      }
      Categories {
        data {
          Label
          Relatable
          Slug
        }
      }
      Authors {
        data {
          Name
          Slug
        }
      }
      Notes {
        data {
          Note
          Contributor {
            data {
              Slug
              Short_Name
            }
          }
          Created_Date
          Updated_Date
        }
      }
    }
  }
  books:allAirtable(filter: {table: {eq: "Books"}, data: {Published: {eq: true}}}) {
    edges {
      node {
        data {
          ...BookForListing
          Categories {
            data {
              Label
              Relatable
              Slug
            }
          }
        }
      }
    }
  }
}
`;
